<template>
  <div class="m-10">loading...</div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {
    this.$vs.loading();
    setTimeout(this.sendMaskRequest, 1000);
  },
  methods: {
    sendMaskRequest() {
      let redirectUrl = "https://api.dok.ma/api/v1/".concat(
        this.$route.params.maskPath
      );
      if (this.$route.query.token) {
        redirectUrl += `?token=${this.$route.query.token}`;
      }
      window.location = redirectUrl;
    },
  },
};
</script>

<style lang="scss">
button,
select {
}

input {
  font-size: 17px;
  &::placeholder {
    font-size: 17px;
    padding: 0px 2px;
  }
}

label {
  font-size: 16px;
  font-weight: 500;
  color: rgb(0, 0, 0);
}
</style>
